// import iconImg from '../../../../../Assets/Images/web-design.svg'

import { useContext } from 'react'
import './SingleService.css'
import AppContext from '../../../../../AppContext/AppContext'

function SingleService({ serviceInfo }) {
    const { setShowConsultationForm } = useContext(AppContext)

    return (
        <div className='single_service'>
            <img src={serviceInfo.image} alt="icon" />
            <h4>{serviceInfo.title}</h4>
            <div className="desc_box">
                <p><i>{serviceInfo.shortDescription}</i></p>
                <p>{serviceInfo.description}</p>
            </div>
            <button type='button' className='btn fill' onClick={() => { setShowConsultationForm(true) }}>
                <span>Get the Services</span>
            </button>
        </div>
    )
}

export default SingleService