import { useContext, useState } from 'react'
import AppContext from '../../../../AppContext/AppContext'
import MTTCard from './MTTCard/MTTCard'

import './MeetTTeam.css'
import Modal from '../../../Layouts/Modal/Modal'
import TMDetails from './TMDetails/TMDetails'
import OtheMembersCard from './OtheMembers/OtheMembersCard'

function MeetTTeam() {

    const { showTeamMember, setShowTeamMember } = useContext(AppContext)
    const [memberInfo, setMemberInfo] = useState(null)

    const meetTheTeams = [
        {
            name: "Walid Zaman",
            title: "Director & Strategic Advisor",
            experience: "EXPERIENCE - JPMORGAN, MKP CAPITAL, GSR",
            description: "Ex Wall Street professional with prior involvement at recognized institutions  including JP Morgan, MKP Capital, The World Bank and GSR Markets. Walid holds a Masters Degree in Finance with significant experience in driving technology led growth and automation. Walid is an advocate for sustainable technological advancement and the responsible utilization of high-quality tech resources in emerging markets, which he believes is critical for the next leap in our technological evolution.  A Bangladeshi by birth, Walid has been residing in the United States since he was a teenager, having lived in multiple states across the country. He frequently travels to Dhaka, where he maintains a strong professional network. As a Managing Director at SparkNation Labs, Walid leads the charge in redefining how businesses utilize digital solutions and is a key relationship manager.",
            link: "https://www.linkedin.com/in/walid-zaman",
            image: "/team_images/Picture3.jpg"

        },
        {
            name: "SHEEHAN RAHMAN",
            title: "Chief Technology Officer",
            experience: "EXPERIENCE - ANTOPOLIS, SEVENTEEN",
            description: "Ex Wall Street professional with prior involvement at recognized institutions  including JP Morgan, MKP Capital, The World Bank and GSR Markets. Walid holds a Masters Degree in Finance with significant experience in driving technology led growth and automation. Walid is an advocate for sustainable technological advancement and the responsible utilization of high-quality tech resources in emerging markets, which he believes is critical for the next leap in our technological evolution.  A Bangladeshi by birth, Walid has been residing in the United States since he was a teenager, having lived in multiple states across the country. He frequently travels to Dhaka, where he maintains a strong professional network. As a Managing Director at SparkNation Labs, Walid leads the charge in redefining how businesses utilize digital solutions and is a key relationship manager.",
            link: "https://www.linkedin.com/in/sheehan-rahman-b2a0b340/",
            image: "/team_images/Sheehan.jpg"

        },
        {
            name: "EZRA SCHATZ",
            title: "Director of Business Development",
            experience: "EXPERIENCE - R NEW YORK, TMP WORLDWIDE, WAYUP",
            description: "Pierre is a German American national with a distinguished track record in the United States in multiple industries. He has a degree in Entrepreneurship and Management. A dedicated changemaker, Pierre initially found success with a real estate investment company he founded. He has also managed operations for Aldi, one of the world’s leading grocery retailers. Pierre envisions a level playing field for offshore technology providers and has high conviction in Bangladesh’s emergence as a top tech hub, where he holds strong ties as well. Along with leading Sparknation Labs’s business expansion in the US, he is also involved in the private aviation industry where he leads a team of client services professionals.",
            link: "https://www.linkedin.com/in/ezraschatz/",
            image: "/team_images/Picture4.jpg"

        },
        {
            name: "PIERRE WALTER",
            title: "Director of Business Development",
            experience: "EXPERIENCE - ALDI, SOLAIRUS AVIATION",
            description: "Pierre is a German American national with a distinguished track record in the United States in multiple industries. He has a degree in Entrepreneurship and Management. A dedicated changemaker, Pierre initially found success with a real estate investment company he founded. He has also managed operations for Aldi, one of the world’s leading grocery retailers. Pierre envisions a level playing field for offshore technology providers and has high conviction in Bangladesh’s emergence as a top tech hub, where he holds strong ties as well. Along with leading Sparknation Labs’s business expansion in the US, he is also involved in the private aviation industry where he leads a team of client services professionals.",
            link: "https://www.linkedin.com/in/pierre-walter-2187-pw",
            image: "https://static-projects-test.sgp1.cdn.digitaloceanspaces.com/optivity/team/Pierro_Walter.png"

        },
        {
            name: "ASIF AHMED",
            title: "Head of Products",
            experience: "EXPERIENCE - NBC UNIVERSAL, PUBLICIS GROUP",
            description: "Pierre is a German American national with a distinguished track record in the United States in multiple industries. He has a degree in Entrepreneurship and Management. A dedicated changemaker, Pierre initially found success with a real estate investment company he founded. He has also managed operations for Aldi, one of the world’s leading grocery retailers. Pierre envisions a level playing field for offshore technology providers and has high conviction in Bangladesh’s emergence as a top tech hub, where he holds strong ties as well. Along with leading Sparknation Labs’s business expansion in the US, he is also involved in the private aviation industry where he leads a team of client services professionals.",
            link: "https://www.linkedin.com/in/asif-ahmed-3810a0b6/",
            image: "/team_images/Picture6.jpg"

        },

    ]
    const otherKeyMembers = [
        {
            name: "Sheehan Rahman",
            title: "Head of Projects and Development",
            description: "Sheehan is a seasoned entrepreneur and skilled developer with a notable portfolio of successful projects. As the founder of a digital marketing firm and tech consulting venture, Antopolis, he brings over 14+ years of experience in driving businesses efficiently. With a passion for empowering entrepreneurs like himself through technology, Sheehan oversees the projects at Sparknation Labs and is the main liaison for all development and engineering concerns.",
        },
        {
            name: "Ashiq Zaman",
            title: " Head of Operations",
            description: "Ashiq is a civil engineer and a seasoned consultant. He has experience with large scale engineering projects, including road infrastructure projects with the Victorian State Government in Australia. Ashiq has been steadily involved in the expansion of the offshore tech industry in Bangladesh as a Business Consultant for Selise Digital Platforms. Ashiq is in charge of all operational processes at the heart of Sparknation Labs, particularly ensuring the smooth functioning of the Bangladesh office.",
        }
    ]
    return (
        <>
            <section className="mtt_sec py_10" id='mtTeam'>
                <div className="container">
                    <div className="sc_title_wrapper center">
                        <h1 className="title_1 center">Meet The Team</h1>
                        {/* <p className='desc _568'>“Lorem ipsum dolor sit amet consectetur. Tortor tempus cursus leo dictumst elementum.</p> */}
                    </div>
                    <div className='flex_container'>
                        {
                            meetTheTeams.map(item => (
                                <MTTCard
                                    setMemberInfo={setMemberInfo}
                                    item={item}
                                    key={item.name}
                                    setShowTeamMember={setShowTeamMember}
                                />
                            ))}
                    </div>
                    {/* <div className='others-members'>
                        <div className="sc_title_wrapper center">
                            <h1 className=" member-title">Other Key Members</h1> 
                           
                        </div>
                        <div className='flex_container '>
                            {
                                otherKeyMembers.map(item => (
                                    <OtheMembersCard
                                        setMemberInfo={setMemberInfo}
                                        item={item}
                                        key={item.name}
                                        setShowTeamMember={setShowTeamMember}
                                    />
                                ))}
                        </div>
                    </div> */}
                </div>

                <div className="container">
                    <div className="sc_title_wrapper center">
                        <p className="desc portfolio-desc">
                            Behind SNLabs's leadership lies a powerhouse of young, creative, and ambitious professionals spanning a spectrum of expertise. Our team is the driving force behind our innovative solutions and our relentless commitment to placing clients at the center of our pursuits. Together, we are forging the path to a future defined by cutting-edge digital solutions and technology-driven progress.
                        </p>
                    </div>
                </div>
            </section>

            {showTeamMember &&
                <Modal>
                    <TMDetails memberInfo={memberInfo} />
                </Modal>
            }
        </>
    )
}

export default MeetTTeam