import { useContext } from 'react';
import AppContext from '../../../../AppContext/AppContext';
import banImg from '../../../../Assets/Images/banner-img.png'

import './VideoBanner.css'

function VideoBanner() {

    const { setShowConsultationForm } = useContext(AppContext)

    return (
        <section className="video_banner" id='home'>
            <div className="bg_video"> 
                <img src={banImg} alt="banner" className='banner_img' />

                <video autoPlay loop muted playsInline>
                    <source src='https://strides-test.sgp1.cdn.digitaloceanspaces.com/abstract-purple-backgrounds-loopable-2022-09-08-03-57-39-utc.mp4' />
                </video>
                <div className="content">
                    <div className='content_inner'>
                        <h2>Welcome to Sparknation Labs</h2>
                        <p className='desc'>At Sparknation Labs, we're more than just an offshore partner. We serve as your strategic ally in crafting innovative solutions, supercharging your business to new heights. Whether you are an aspiring startup or small business, or an ambitious solopreneur, get powered by our industry leading software design and development, along with intelligent analytics and digital marketing solutions to elevate your business.
                        </p>
                        <button className="btn fill" onClick={() => setShowConsultationForm(true)}>
                            <span>Get free consultation</span>
                        </button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default VideoBanner