import React from "react";
import './ClientPortfolio.css'
function ClientPortfolio({ portfolioInfo }) {
  return (
    <div className="client_portfolio">
      <div className="single_service">
        <img src={portfolioInfo.image} alt="icon" />
        <h4>{portfolioInfo.title}</h4>
        <div className="desc_box">
          <p>{portfolioInfo.description}</p>
        </div>
      </div>
    </div>
  );
}

export default ClientPortfolio;
