// import Wcu from '../../../../../Assets/Images/wcu.png'

import "./WCUCard.css"

function WCUCard({ item }) {
    return (
        <div className="wcu_card">
            <div className="wcu_card_inner">
                <img src={item.image} alt="icon" />
                <h5>{item.title}</h5>
                <p className="desc">{item.description}</p>
            </div>
        </div>
    )
}

export default WCUCard