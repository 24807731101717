// import mttImg from '../../../../../Assets/Images/mttimg.png'
import { ReactComponent as Arrow } from '../../../../../Assets/Icons/arrow-up-right.svg'

import './MTTCard.css'
import TMSocials from '../TMSocials/TMSocials'

function MTTCard({ setShowTeamMember, item, setMemberInfo }) {
    return (
        <div className="mtt_card">
            <div className='mtt_card_inner'>
                <img src={item.image} alt='thumb' />
                <div className='content'>
                    <div className="flex_content">
                        <div className='min-height'>
                            <h5>{item.name}</h5>
                            <span>{item.title}</span>
                            <TMSocials link={item.link}/>
                        </div>
                        <button
                            type="button"
                            className='btn_arrow'
                            onClick={() => {
                                setMemberInfo(item)
                                setShowTeamMember(true)
                            }
                            }
                        >
                            <Arrow />
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MTTCard