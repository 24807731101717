import { useContext } from 'react';
import { RiCloseLine } from 'react-icons/ri'
import AppContext from '../../../AppContext/AppContext';

import './Modal.css';

function Modal({ children }) {

    const { closeModal } = useContext(AppContext)

    return (
        <div className={`modal`}>
            <div className="modal_backdrop" onClick={closeModal}></div>
            <div className={`modal_dialog modal_dialog_centered modal_dialog_scrollable`}>
                <div className="modal_content">
                    <button type='button' className='modal_close' onClick={closeModal}><RiCloseLine /></button>
                    <div className="modal_body">{children}</div>
                </div>
            </div>
        </div>
    )
}
export default Modal