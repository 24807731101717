import { Link } from 'react-router-dom'

import './Footer.css'

function Footer() {
  return (
    <footer className='footer'>
      <div className="container">
        <div className="flex_container">
          <p>Copyright © 2023 Sparknation Labs</p>
          <div>
            <Link to="/privacy-policy">Privacy Policy</Link>

            <Link to="/terms">Terms And Condition</Link>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer