import { useState } from "react"

import owImg from '../../../../Assets/Images/owImg.png'

import './OptivityWay.css'
import { Link } from "react-router-dom"
import logoSm from '../../../../Assets/Logos/nav-logo-sm.svg'


function OptivityWay() {

    const [activeTab, setActiveTab] = useState('as')


    return (
        <section className="py_10 optivity_way_sec" id="aboutUs">
            <div className="container">
                <div className="grid_container">
                    <div className="grid_item">
                        <div className=" modal_icon">
                            <Link to='/' className='logo_wrapper'>
                                <img src={logoSm} alt="brand" className="logo_sm" />
                            </Link>
                            <h1 className="title_1 center">The SNLabs Way</h1>
                        </div>
                        <nav>
                            <button
                                type="button"
                                className={`${activeTab === 'as' ? 'active' : ''}`}
                                onClick={() => setActiveTab('as')}
                            >About Us</button>
                            <button
                                type="button"
                                className={`${activeTab === 'om' ? 'active' : ''}`}
                                onClick={() => setActiveTab('om')}
                            >Our Mission</button>
                            <button
                                type="button"
                                className={`${activeTab === 'ov' ? 'active' : ''}`}
                                onClick={() => setActiveTab('ov')}
                            >Our Vision</button>
                        </nav>
                        <div className="tab_content">
                            {activeTab === 'as' &&
                                <p className="desc">At Sparknation Labs, our ethos is built upon the bedrock of American excellence, embodying the highest standards and values. We firmly believe that software technology should be borderless, eliminating information asymmetry and fragmentation. We put innovation and integrity at our forefront, augmenting firms across industries with cross-border technology solutions. We are backed by a seasoned leadership team based in the United States with access to an exceptional pool of developers from our partner nation Bangladesh.</p>
                            }
                            {activeTab === 'om' &&
                                <p className="desc">Redefining our clients’ potential with innovative tech solutions and practices, optimizing global resource allocation, and fueling growth through excellence and collaboration.</p>
                            }
                            {activeTab === 'ov' &&
                                <p className="desc">
                                    Our philosophy is simple. We see a world with an equitable playing field for businesses, with our global platform spurring tech inclusivity for all.
                                </p>
                            }
                        </div>
                    </div>
                    <div className="grid_item">
                        <img src={owImg} alt="thumb" />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default OptivityWay