// import mttImg from '../../../../../Assets/Images/mttimg.png'
import TMSocials from '../TMSocials/TMSocials'

import './TMDetails.css'

function TMDetails({ memberInfo }) {
  return (
    <div className='tm_details'>
      {
        memberInfo?.image &&
        <img src={memberInfo?.image} alt="bigThumb" />
      }
      <h3>{memberInfo.name}</h3>
      <span>{memberInfo.title}</span>
      <span style={{fontWeight: "bold", textTransform: "uppercase"}}>{memberInfo.experience}</span>
      {/* <p className='desc'>{memberInfo.description}</p> */}

      {memberInfo?.link &&
        <div className="tmd_social">
          <label>Social:</label>
          <TMSocials link={memberInfo.link} />
        </div>}
    </div>
  )
}

export default TMDetails