import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import ClientCard from "./ClientCard/ClientCard";
import AppContext from "../../../../AppContext/AppContext";

import "swiper/css";
import "./Clients.css";
import { useContext, useState } from "react";
import Modal from "../../../Layouts/Modal/Modal";
import ClientPortfolio from "./ClientPortfolio/ClientPortfolio";

function Clients() {
  const { showModalContent,setShowModalContent } = useContext(AppContext);
  const [portfolioInfo, setPortfolioInfo] = useState(null);

  const clientTeams = [
    {
      title: "Restaurant Management System",
      name: "Emerald",
      description: "Emerald Restaurants is a family-owned business with over 15 restaurants, including Kiyoshi, Grove Bistro, Thai Emerald, and Emerald Bakery. Partnering with us, they launched a 360° e-commerce web application that opened up new customer channels, increasing reservations.",
      image:
        "https://static-projects-test.sgp1.cdn.digitaloceanspaces.com/optivity/clients/Emerald.svg",
    },
    {
      title: "Web platform",
      name: "Abelling",
      description:"We created a successful SEO-optimized website for a leading annotation company in the US. The website doubled the client's traffic and helped establish them as a major player in the competitive market. We achieved this by focusing on user experience, content, and strong SEO strategies.",
      image:
        "https://static-projects-test.sgp1.cdn.digitaloceanspaces.com/optivity/clients/Abelling.svg",
    },
    {
      title: "Content marketing and website",
      name: "Toyota",
      description:"Toyota Bangladesh partnered with us to address faulty Takata airbags with a website campaign. The campaign was successful in educating customers and led to the replacement of 1,000 airbags. ",
      image:
        "https://static-projects-test.sgp1.cdn.digitaloceanspaces.com/optivity/clients/Toyota.svg",
    },
    {
      title: "Learning Management System",
      name: "Interport",
      description:
        "Intuitive learning management system for an established Maritime academy.",
      image:
        "https://static-projects-test.sgp1.cdn.digitaloceanspaces.com/optivity/clients/Interport.svg",
    },
    {
      title: "Content Marketing",
      name: " Fish&Co ",
      description: "Social media content to enhance online presence.",
      image:
        "https://static-projects-test.sgp1.cdn.digitaloceanspaces.com/optivity/clients/Fish&Co..svg",
    },
    {
      title: "Ecommerce Platform",
      name: " Neo_Bazaar",
      description:
        "Powerful E-commerce platform for an organic online grocery.",
      image:
        "https://static-projects-test.sgp1.cdn.digitaloceanspaces.com/optivity/clients/Neo_Bazaar.svg",
    },
    // {
    //   title: "Website",
    //   name: "Bastu",
    //   description:
    //     " User-friendly web application for an online property marketplace.",
    //   image:
    //     "https://static-projects-test.sgp1.cdn.digitaloceanspaces.com/optivity/clients/Bastu.svg",
    // },
    {
      title: " Content Marketing",
      name: "/Burger_King",
      description: "Impactful content marketing that turbocharged the renowned franchise's local presence and sales",
      image:
        "https://static-projects-test.sgp1.cdn.digitaloceanspaces.com/optivity/clients/Burger_King.svg",
    },
    {
      title: "Content Marketing",
      tnameitle: "Amari",
      description: "Compelling social media content for luxury hotel chain.",
      image:
        "https://static-projects-test.sgp1.cdn.digitaloceanspaces.com/optivity/clients/Amari.svg",
    },
    {
      title: "Content Marketing",
      name: "YKBI",
      description:
        "Brand awareness campaign with content marketing for premier fitness studio.",
      image:
        "https://static-projects-test.sgp1.cdn.digitaloceanspaces.com/optivity/clients/YKBI.svg",
    },
    // {
    //   title: "Social Media",
    //   name: "Shanta",
    //   description:
    //     "Enhanced social media presence for a leading financial services provider.",
    //   image:
    //     "https://static-projects-test.sgp1.cdn.digitaloceanspaces.com/optivity/clients/Shanta.svg",
    // },
    {
      title: "Animated explainer videos",
      name: "Uber",
      description:
        "Informative animated explainer videos for the ride sharing giant.",
      image:
        "https://static-projects-test.sgp1.cdn.digitaloceanspaces.com/optivity/clients/Uber.svg",
    },
    {
      title: "Social Media management",
      name: "Beximco",
      description:"Partnering with one of the biggest LPG providers of Bangladesh, we built a user-friendly website, focusing on design and education about the company. The responsive design ensured seamless accessibility across devices.",
      image:
        "https://static-projects-test.sgp1.cdn.digitaloceanspaces.com/optivity/clients/Beximco.svg",
    },
  ];

  return (
    <section className="clients_sec pb_10 pt_10" id="portfolio">
      <div className="sc_title_wrapper center">
        <h1 className="title_1 center">Portfolio</h1>
        <p className="desc">
          Explore our work. At Sparknation Labs, we adhere to quality and
          commitment, perpetually raising the bar for excellence.
        </p>
      </div>
      <Swiper
        slidesPerView={2.5}
        spaceBetween={24}
        modules={[Autoplay]}
        autoplay={true}
        speed={2500}
        loop={true}
        breakpoints={{
          768: {
            slidesPerView: 4.25,
          },
          1024: {
            slidesPerView: 5.25,
          },
          1200: {
            slidesPerView: 6.25,
          },
          1440: {
            slidesPerView: 7.25,
          },
        }}
      >
        {clientTeams?.map((client, index) => (
          <SwiperSlide key={index}>
            <ClientCard
              setPortfolioInfo={setPortfolioInfo}
              client={client}
              key={client?.name}
              setShowModalContent={setShowModalContent}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="container">
        <div className="sc_title_wrapper center">
          <p className="desc portfolio-desc">
            We recruit and train qualified developers and engineers through our
            network expertise. Our robust vetting process ensures top tier
            individuals are assigned to your projects, tapping into our growing
            talent pool and reliable partners. We prioritise your ease of
            communication and precise technical fit to maximise your return and
            efficiency.
          </p>
        </div>
      </div>
      {showModalContent && (
        <Modal>
          <ClientPortfolio portfolioInfo={portfolioInfo} />
        </Modal>
      )}
    </section>
  );
}

export default Clients;
