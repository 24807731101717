import LVCard from './LVCard/LVCard'

import './LeadershipValues.css'

function LeadershipValues() {

    const leadershipValues = [
        {
            title: "Workplace Integrity",
            description: "We are committed to fostering an environment of respect and opportunity, providing our employees with optimal infrastructure for success and a culture that enables them to thrive. By prioritizing their well-being and loyalty towards their functions, we ensure the best outcomes for our clients."
        },
        {
            title: "Purpose-Driven Profit",
            description: " By doing the right things and going the extra mile for our clients, we ensure sustainable profitability, aligning our success with our values and principles."
        },
        {
            title: "Relentless Commitment",
            description: "Our job isn't complete until we've achieved our goals, and we persevere until the mission is accomplished, Throughout the journey we are dedicated to providing our clients the best white-glove experience possible."
        },
        {
            title: "Embrace Change with Excellence",
            description: " Change is our constant companion and we prioritize delivering optimal results in a fast evolving industry. We understand that the journey won't always be smooth or pretty, but we embrace challenges as opportunities to grow."
        },
        {
            title: "Recognizing Challenges",
            description: "We're vigilant against dangers like bureaucracy, personal interest, redundancy and energy-draining activities. Our ability to acknowledge these hurdles and weaknesses allows us to prioritize what truly matters."
        }

    ]
    return (
        <section className="leadership_sec py_10">
            <div className="container">
                <div className="sc_title_wrapper center">
                    <h1 className="title_1 center">Leadership Values</h1>
                    {/* <p className='desc'></p> */}
                </div>
                <div className="grid_container">
                    {
                        leadershipValues.map(item => (
                            <LVCard item={item} key={item.title}/>
                        ))}
                </div>
            </div>
        </section>
    )
}

export default LeadershipValues