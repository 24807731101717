import './LVCard.css'

function LVCard({item}) {
    return (
        <div className='lv_card'>
            <div className="lv_card_inner">
                <h4>{item.title}</h4>
                <p className="desc">{item.description}</p>
            </div>
        </div>
    )
}

export default LVCard