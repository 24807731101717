import { useState, useEffect } from 'react';

import NavLarge from './NavLarge/NavLarge'
import NavSmall from './NavSmall/NavSmall'

import './Navbar.css'

function Navbar() {

  const [showSmallNav, setShowSmallNav] = useState(false);
    const [scroll, setScroll] = useState(false)

    useEffect(() => {
        window.addEventListener("scroll", () => {
            setScroll(window.scrollY > 80)
        })
    }, []);

  const smallNavToggle = () => {
    setShowSmallNav(prevState => !prevState)
  }

  return (
    <section className={`navbar ${scroll && 'bg_dark'}`}>
      <NavLarge
        smallNavToggle={smallNavToggle}
      />
      <NavSmall
        showSmallNav={showSmallNav}
        smallNavToggle={smallNavToggle}
      />
    </section>
  )
}

export default Navbar