import Policy from "./Policy/Policy";

import "./PrivacyPolicy.css";

function PrivacyPolicy() {
  const ourPolicy = [
    {
      number: "1.",
      title: "Introduction",
      description: "",
    },
  ];

  return (
    <section className="scu_sec py_10 privacy_policy">
      <div className="container">
        <div className="content_inner">
          <h2>Sparknation Labs LLC Privacy Policy </h2>
          <div className="desc">
            <p>
              Sparknation Labs LLC ("SNLabs," "we,"”our,” or "us") is committed
              to protecting your privacy. This Privacy Policy explains how we
              collect, use, disclose, and safeguard your personal information
            </p>
            <p>
              Sparknation Labs LLC (the “Company”) is committed to maintaining
              robust privacy protections for its users. Our Privacy Policy
              (“Privacy Policy”) is designed to help you understand how we
              collect, use and safeguard the information you provide to us and
              to assist you in making informed decisions when using our Service.
            </p>
            <p>
              For purposes of this Agreement, “Site” refers to the Company’s
              website, which can be accessed at www.sparknationlabs.com
              “Service” refers to the Company’s services offered via the Site,
              in which users can review our Website Development, Mobile App
              Development, Web Applications and SaaS Solutions, Data Labeling,
              Digital Marketing, Startup Launchpad offerings.
            </p>
            <p>
              The terms “we,” “us,” and “our” refer to the Company. “You” refers
              to you, as a user of our Site or our Service. By accessing our
              Site or our Service, you accept our Privacy Policy and Terms of
              Use (found here: <a href="https://www.sparknationlabs.com/terms">https://www.sparknationlabs.com/terms</a>, and you
              consent to our collection, storage, use and disclosure of your
              Personal Information as described in this Privacy Policy.
            </p>
          </div>
          <div className="desc">
            <h3>I. INFORMATION WE COLLECT </h3>
            <p>
              We collect “Non-Personal Information” and “Personal Information.”
              Non-Personal Information includes information that cannot be used
              to personally identify you, such as anonymous usage data, general
              demographic information we may collect, referring/exit pages and
              URLs, platform types, preferences you submit and preferences that
              are generated based on the data you submit and number of clicks.
              Personal Information includes your email, names, phone numbers,
              contact preferences, contact or authentication data, service
              preferred, which you submit to us through the Consultancy Request
              Form process at the Site. We do not process sensitive information.
            </p>
            <h5>1. Information collected via Technology</h5>
            <p>
              To activate the Service you do not need to submit any Personal
              Information other than your name, service preferred and email
              address. To use the Service thereafter, you do not need to submit
              further Personal Information. However, in an effort to improve the
              quality of the Service, we track information provided to us by
              your browser or by our software application when you view or use
              the Service, such as the website you came from (known as the
              “referring URL”), the type of browser you use, the device from
              which you connected to the Service, the time and date of access,
              and other information that does not personally identify you. We
              track this information using cookies, or small text files which
              include an anonymous unique identifier. Cookies are sent to a
              user’s browser from our servers and are stored on the user’s
              computer hard drive. Sending a cookie to a user’s browser enables
              us to collect Non-Personal information about that user and keep a
              record of the user’s preferences when utilizing our services, both
              on an individual and aggregate basis. For example, the Company may
              use cookies to collect the following information: Log and Usage
              Data, Device Data, Location Data The Company may use both
              persistent and session cookies; persistent cookies remain on your
              computer after you close your session and until you delete them,
              while session cookies expire when you close your browser. For
              example, we store a persistent cookie to track user preferences or
              saved credentials.
            </p>
            <h5>2. Information you provide us by registering for an account</h5>
            <p>
              In addition to the information provided automatically by your
              browser when you visit the Site, to become a subscriber to the
              Service you may need to create a personal profile. You can create
              a profile by registering with the Service and entering your email
              address, and creating a user name and a password. By registering,
              you are authorizing us to collect, store and use your email
              address in accordance with this Privacy Policy.
            </p>
            <h5>3. Children’s Privacy</h5>
            <p>
              The Site and the Service are not directed to anyone under the age
              of 13. The Site does not knowingly collect or solicit information
              from anyone under the age of 13, or allow anyone under the age of
              13 to sign up for the Service. In the event that we learn that we
              have gathered personal information from anyone under the age of 13
              without the consent of a parent or guardian, we will delete that
              information as soon as possible. If you believe we have collected
              such information, please contact us at info@sparknationlabs.com.
            </p>
            <p></p>
          </div>
          <div className="desc">
            <h3>II. HOW WE PROCESS, USE AND SHARE INFORMATION </h3>
            <p>
              <i>Personal Information:</i>
            </p>
            <p>
              Except as otherwise stated in this Privacy Policy, we do not sell,
              trade, rent or otherwise share for marketing purposes your
              Personal Information with third parties without your consent. We
              do share Personal Information with vendors who are performing
              services for the Company, such as the servers for our email
              communications who are provided access to user’s email address for
              purposes of sending emails from us. Those vendors use your
              Personal Information only at our direction and in accordance with
              our Privacy Policy. In general, the Personal Information you
              provide to us is used to help us communicate with you, to deliver
              and facilitate delivery of services to the user, request feedback,
              send relevant marketing and promotional communications, deliver
              targeted advertising, identify user trends, save or protect an
              individual’s vital interest . For example, we use Personal
              Information to contact users in response to questions, solicit
              feedback from users, provide technical support, and inform users
              about promotional offers. We may share Personal Information with
              outside parties if we have a good-faith belief that access, use,
              preservation or disclosure of the information is reasonably
              necessary to meet any applicable legal process or enforceable
              governmental request; to enforce applicable Terms of Service,
              including investigation of potential violations; address fraud,
              security or technical concerns; or to protect against harm to the
              rights, property, or safety of our users or the public as required
              or permitted by law. We may need to share your personal
              information for Business Transfers (Mergers or Acquisitions),
              Affiliates who honor this privacy notice, Business Partners. We
              only process your personal information when we believe it is
              necessary and we have a valid legal reason (i.e., legal basis) to
              do so under applicable law, like with your consent, to comply with
              laws, to provide you with services to enter into or fulfill our
              contractual obligations, to protect your rights, or to fulfill our
              legitimate business interests
            </p>
            <p>
              <u>
                If you are located in the EU or UK, this section applies to you
              </u>
            </p>
            <p>
              The General Data Protection Regulation (GDPR) and UK GDPR require
              us to explain the valid legal bases we rely on in order to process
              your personal information. As such, we may rely on the following
              legal bases to process your personal information: Consent,
              Legitimate Interests, Contract Performance and Contractual
              Obligations, Legitimate Vital & Business Interests, Legal
              Obligations. You can withdraw your consent at any time.
            </p>
            <p>
              <u>If you are located in Canada, this section applies to you.</u>
            </p>
            <p>
              We may process your information if you have given us specific
              permission (i.e., express consent) to use your personal
              information for a specific purpose, or in situations where your
              permission can be inferred (i.e., implied consent). You can
              withdraw your consent at any time. In special cases, we may be
              legally permitted under applicable law to process your information
              without your consent : Fraud, Business Transactions, Injured or
              Deceased Persons, Clear Interest of Individual, Victim of
              Financial Abuse, Court Orders, Publicly Available Information,
              Collection Exclusively for journalistic, artistic, or literary
              purposes.
            </p>
            <p>
              <i>Non-Personal Information:</i>
            </p>
            <p>
              In general, we use Non-Personal Information to help us improve the
              Service and customize the user experience. We also aggregate
              Non-Personal Information in order to track trends and analyze use
              patterns on the Site. This Privacy Policy does not limit in any
              way our use or disclosure of Non-Personal Information and we
              reserve the right to use and disclose such Non-Personal
              Information to our partners, advertisers and other third parties
              at our discretion. In the event we undergo a business transaction
              such as a merger, acquisition by another company, or sale of all
              or a portion of our assets, your Personal Information may be among
              the assets transferred. You acknowledge and consent that such
              transfers may occur and are permitted by this Privacy Policy, and
              that any acquirer of our assets may continue to process your
              Personal Information as set forth in this Privacy Policy. If our
              information practices change at any time in the future, we will
              post the policy changes to the Site so that you may opt out of the
              new information practices. We suggest that you check the Site
              periodically if you are concerned about how your information is
              used.
            </p>
          </div>
          <div className="desc">
            <h3>III. HOW WE PROTECT INFORMATION</h3>
            <p>
              We implement security measures designed to protect your
              information from unauthorized access. Your account is protected by
              your account password and we urge you to take steps to keep your
              personal information safe by not disclosing your password and by
              logging out of your account after each use. We further protect
              your information from potential security breaches by implementing
              certain technological security measures including encryption,
              firewalls and secure socket layer technology. However, these
              measures do not guarantee that your information will not be
              accessed, disclosed, altered or destroyed by breach of such
              firewalls and secure server software. No electronic transmission
              over the Internet or information storage technology can be
              guaranteed to be 100% secure, so we cannot promise or guarantee
              that hackers, cybercriminals, or other unauthorized third parties
              will not be able to defeat our security and improperly collect,
              access, steal, or modify your informationBy using our Service, you
              acknowledge that you understand and agree to assume these risks.
            </p>
          </div>
          <div className="desc">
            <h3>
              IV. YOUR RIGHTS REGARDING THE USE OF YOUR PERSONAL INFORMATION
            </h3>
            <p>
              You have the right at any time to prevent us from contacting you
              for marketing purposes. When we send a promotional communication
              to a user, the user can opt out of further promotional
              communications by following the unsubscribe instructions provided
              in each promotional email. You can also indicate that you do not
              wish to receive marketing communications from us in the
              Unsubscribe settings page of the Site or Unsubscribe link in the
              emails. Please note that notwithstanding the promotional
              preferences you indicate by either unsubscribing or opting out in
              the Unsubscribe settings page of the Site or Unsubscribe link in
              the emails, we may continue to send you administrative emails
              including, for example, periodic updates to our Privacy Policy.
              Withdrawing your consent: If we are relying on your consent to
              process your personal information, which may be express and/or
              implied consent depending on the applicable law, you have the
              right to withdraw your consent at any time. You can withdraw your
              consent at any time by contacting us by using the contact details
              provided in the section CONTACT US below.In regions (like the EEA,
              UK, Switzerland, and Canada), you have certain rights under
              applicable data protection laws. These may include the right (i)
              to request access and obtain a copy of your personal information,
              (ii) to request rectification or erasure; (iii) to restrict the
              processing of your personal information; (iv) if applicable, to
              data portability; and (v) not to be subject to automated
              decision-making. In certain circumstances, you may also have the
              right to object to the processing of your personal information.
              You can make such a request by contacting us by using the contact
              details provided in the section CONTACT US below. We will consider
              and act upon any request in accordance with applicable data
              protection laws. If you are located in the EEA or UK and you
              believe we are unlawfully processing your personal information,
              you have the right to complain to your Member State data
              protection authority or UK data protection authority. If you are
              located in Switzerland, you can contact the Federal Data
              Protection and Information Commissioner. Do Not Track (DNT)
              feature or setting allows individuals to set privacy preferences.
              So far no uniform standard has been finalized and as such we do
              not currently respond to DNT browser signals or any other
              mechanism that automatically communicates your choice not to be
              tracked online. If a standard for online tracking is adopted that
              we must follow in the future, we will inform you about that
              practice in a revised version of this privacy notice. If you have
              questions or comments about your privacy rights, you may email us
              at info@sparknationlabs.com.
            </p>
          </div>
          <div className="desc">
            <h3> V. LINKS TO OTHER WEBSITES </h3>
            <p>
              The Company reserves the right to change this policy and our Terms
              of Service at any time. We will notify you of significant changes
              to our Privacy Policy by sending a notice to the primary email
              address specified in your account or by placing a prominent notice
              on our site. Significant changes will go into effect 30 days
              following such notification. Non-material changes or
              clarifications will take effect immediately. You should
              periodically check the Site and this privacy page for updates.
            </p>
          </div>
          <div className="desc">
              <h3> VII. CONTACT US </h3>
            <div className="contact_us">
              <p>
                If you have questions or comments about this notice, you may
                email us at
                <br />
                info@sparknationlabs.com or contact us by post at:
              </p>
              <p>
                Sparknation Labs LLC
                <br />
                6 Oliver Park drive
                <br />
                Annville, PA 17003
                <br />
                United States
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
