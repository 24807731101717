import './Home.css'

import VideoBanner from './VideoBanner/VideoBanner'
import WhyChooseUs from './WhyChooseUs/WhyChooseUs'
import OptivityWay from './OptivityWay/OptivityWay'
// import Testimonials from './Testimonials/Testimonials'
import Clients from './Clients/Clients'
import MeetTTeam from './MeetTTeam/MeetTTeam'
import FAQs from './FAQs/FAQs'
import ContactUs from './ContactUs/ContactUs'
import OurServices from './OurServices/OurServices'
// import WhoWeAre from './WhoWeAre/WhoWeAre'
import LeadershipValues from './LeadershipValues/LeadershipValues'

function Home() {
  return (
    <main className='home_page'>
      <VideoBanner />
      <WhyChooseUs />
      <OptivityWay />
      {/* <WhoWeAre /> */}
      <LeadershipValues />
      <OurServices/>
      {/* <Testimonials /> */}
      <Clients />
      <MeetTTeam />
      <FAQs />
      <ContactUs />
    </main>
  )
}

export default Home