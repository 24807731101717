import { useState } from 'react'
import contTopImg from '../../../../Assets/Images/contact-top.png'
import SocialLinks from '../../../Layouts/SocialLinks/SocialLinks'

import './ContactUs.css'
import axios from 'axios'
import { toast } from 'react-toastify'

function ContactUs() {

    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')


    async function handleSubmit(e) {

        e.preventDefault()

        const itemData = {
            name,
            email,
            phone,
            subject,
            message,
        }

        const formData = await axios.post(process.env.REACT_APP_BACKEND_URL + 'api/public/affiliateConsultancy/snlabs', itemData)

        if (formData.status === 200) {
            toast.success('Data successfully submitted', {
                theme: 'dark',
            });
            setName('')
            setEmail('')
            setPhone('')
            setSubject('')
            setMessage('')
        }
    }

    return (
        <section className="contact_sec pb_10" id='contactUs'>
            <div className="contact_sc_top_img">
                <img src={contTopImg} alt="topThumb" />
            </div>
            <div className="container pt_10">
                <div className="sc_title_wrapper center">
                    <h1 className="title_1 center">We'd love to chat with you</h1>
                    {/* <p className='desc _568'>“Lorem ipsum dolor sit amet consectetur. Tortor tempus cursus leo dictumst elementum.</p> */}
                </div>
                <div className="grid_container">
                    <div className="grid_item">
                        <div>
                            <h5>Contact us directly or leave your details for us to review</h5>
                        </div>
                        {/* <div>
                            <span>Phone:</span>
                            <h6>(123) 456-7890</h6>
                        </div> */}
                        <div>
                            <span>Email:</span>
                            <h6>info@sparknationlabs.com</h6>
                        </div>
                        <div>
                            <span>Social:</span>
                            <SocialLinks />
                        </div>
                    </div>
                    <div className="grid_item">
                        <form className="form_wrapper" onSubmit={handleSubmit}>
                            {/* <input type="text" placeholder="Your Name" />
                            <input type="text" placeholder="Phone Number" />
                            <input type="email" placeholder="Your Email" />
                            <input type="text" placeholder="Your Subject" />
                            <textarea rows="6" placeholder="Your Message"></textarea> */}
                            <input
                                type="text"
                                placeholder="Your Name"
                                value={name}
                                onChange={e => setName(e.target.value)}
                                required
                            />
                            <input
                                type="text"
                                placeholder="Phone Number"
                                value={phone}
                                onChange={e => setPhone(e.target.value)}
                                required

                            />
                            <input
                                type="email"
                                placeholder="Your Email"
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                required

                            />
                            <input
                                type="text"
                                placeholder="Your Subject"
                                value={subject}
                                onChange={e => setSubject(e.target.value)}
                                required

                            />
                            <textarea
                                rows="6"
                                placeholder="Your Message"
                                value={message}
                                onChange={e => setMessage(e.target.value)}
                                required
                            ></textarea>
                            <button type="submit" className="btn fill"><span>Submit Now</span></button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactUs