import WCUCard from "./WCUCard/WCUCard"

import './WhyChooseUs.css'

function WhyChooseUs() {

    const WhyChooseUs = [
        {
            title: "Allocate Smartly",
            description: "Access top tech talent and developers at the most competitive rates.",
            image: "https://static-projects-test.sgp1.cdn.digitaloceanspaces.com/optivity/1.svg"
        },
        {
            title: "Comprehensive Services",
            description: " One-stop shop to cater to your specific digital requirements",
            image: "https://static-projects-test.sgp1.cdn.digitaloceanspaces.com/optivity/2.svg"
        },
        {
            title: "Community Empowerment",
            description: "Committed to bridging the digital divide with sustainable practices",
            image: "https://static-projects-test.sgp1.cdn.digitaloceanspaces.com/optivity/3.svg"
        },
        {
            title: "Leverage Next-Level Engineering",
            description: "Faster, smarter and client centric development process",
            image: "https://static-projects-test.sgp1.cdn.digitaloceanspaces.com/optivity/4.svg"
        },
        {
            title: "AI Power Up",
            description: "New spectrum of possibilities with our generative AI capabilities.",
            image: "https://static-projects-test.sgp1.cdn.digitaloceanspaces.com/optivity/5.svg"
        },
        {
            title: "Dedicated Support",
            description: "Seamless communication and on-demand assistance with dedicated managers",
            image: "https://static-projects-test.sgp1.cdn.digitaloceanspaces.com/optivity/6.svg"
        },
    ]
    return (
        <section className="scu_sec py_10">
            <div className="container">
                <div className="sc_title_wrapper center">
                    <h1 className="title_1 center">Why Choose Us?</h1>
                    {/* <p className="desc"></p> */}
                </div>
                <div className="grid_container">
                    {WhyChooseUs.map(item => (
                        <WCUCard item={item} key={item.title} />
                    ))}
                </div>
            </div>
        </section>
    )
}

export default WhyChooseUs