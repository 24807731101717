import { createContext, useState } from "react";

const AppContext = createContext({});

function AppContextProvider({ children }) {
  const [showConsultationForm, setShowConsultationForm] = useState(false);
  const [showSingleService, setshowSingleService] = useState(false);
  const [showTeamMember, setShowTeamMember] = useState(false);
  const [showModalContent, setShowModalContent] = useState(false);

  const closeModal = () => {
    setShowConsultationForm(false);
    setshowSingleService(false);
    setShowTeamMember(false);
    setShowModalContent(false);
  };

  return (
    <AppContext.Provider
      value={{
        showConsultationForm,
        setShowConsultationForm,
        showSingleService,
        setshowSingleService,
        showTeamMember,
        setShowTeamMember,
        closeModal,
        showModalContent,
        setShowModalContent,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export { AppContextProvider };

export default AppContext;
