import { useContext, useState } from 'react';
import { Link } from 'react-router-dom'
import AppContext from '../../../../AppContext/AppContext'

import { ReactComponent as MenuIcon } from '../../../../Assets/Icons/menu-icon.svg'
import logoLg from '../../../../Assets/Logos/nav-logo-lg.svg'
import logoSm from '../../../../Assets/Logos/nav-logo-sm.svg'

import './NavLarge.css'

function NavLarge({ smallNavToggle }) {

    const { setShowConsultationForm } = useContext(AppContext)

    const [activeNav, setActiveNav] = useState('home')


    return (
        <div className="container">
            <div className="nav_large">
                <Link to='/' className='logo_wrapper'>
                    <img src={logoLg} alt="brand" className="logo_lg" />
                    <img src={logoSm} alt="brand" className="logo_sm" />
                </Link>
                <nav>

                    {/* <a
                        href="#home"
                        className={`${activeNav === "home" ? 'active' : ''}`}
                        onClick={() => setActiveNav('home')}
                    >
                        home
                    </a> */}
                    <a
                        href="#aboutUs"
                        className={`${activeNav === "about" ? 'active' : ''}`}
                        onClick={() => setActiveNav('about')}
                    >
                        About Us
                    </a>
                    <a
                        href="#services"
                        className={`${activeNav === "service" ? 'active' : ''}`}
                        onClick={() => setActiveNav('service')}
                    >
                        Services
                    </a>

                    {/* <a
                        href="# href="#services""
                        className={`${activeNav === "testimonial" ? 'active' : ''}`}
                        onClick={() => setActiveNav('testimonial')}
                    >
                        Testimonials
                    </a> */}
                    <a
                        href="#portfolio"
                        className={`${activeNav === "portfolio" ? 'active' : ''}`}
                        onClick={() => setActiveNav('portfolio')}
                    >
                        Portfolio
                    </a>
                    <a
                        href="#mtTeam"
                        className={`${activeNav === "mtTeam" ? 'active' : ''}`}
                        onClick={() => setActiveNav('mtTeam')}
                    >
                        Meet The Team
                    </a>
                    <a
                        href="#contactUs"
                        className={`${activeNav === "contact" ? 'active' : ''}`}
                        onClick={() => setActiveNav('contact')}
                    >
                        Contact Us
                    </a>

                    {/* <NavLink to='/'>home</NavLink>
                    <NavLink to='/aboutUs'>About Us</NavLink>
                    <NavLink to='/process'>services</NavLink>
                    <NavLink to='/meetTheTeam'>Meet The Team</NavLink>
                    <NavLink to='/testimonials'>Testimonials</NavLink>
                    <NavLink to='/contactUs'>Contact Us</NavLink> */}

                    <button type='button' className='btn' onClick={() => { setShowConsultationForm(true) }}>
                        <span>Get free consultation</span>
                    </button>
                </nav>
                <button className="btn_nst" onClick={smallNavToggle}>
                    <MenuIcon />
                </button>
            </div>
        </div>
    )
}

export default NavLarge