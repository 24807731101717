import { ReactComponent as Arrow } from '../../../../../Assets/Icons/arrow-up-right.svg'

import './ServiceCard.css'

function ServiceCard({ setshowSingleService, item, setServiceInfo }) {
    return (
        <div
            className='service_card'
            onClick={() => {
                setshowSingleService(true)
                setServiceInfo(item)
            }}
        >
            <div className="icon_box">
                <img src={item.image} alt="icon" className="icon" />
            </div>
            <h5>{item.title}</h5>
            <p className="desc">{item.shortDescription}</p>
            <button type="button" className='btn_explore'>Explore More</button>
            <button type="button" className='btn_arrow'>
                <Arrow />
            </button>
        </div>
    )
}

export default ServiceCard