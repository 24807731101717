// import { useEffect, useState } from 'react';
// import axios from 'axios';
// import { FAQ_API } from '../../../Utilities/APIs';

import { useState } from 'react';
import './FAQs.css'

function FAQs() {

    // const [FAQs, setFAQs] = useState(null);
    const [activeFAQId, setActiveFAQId] = useState('');
    const [activeFAQ, setActiveFAQ] = useState(false)

    const fAQs = [
        {
            id: '1',
            title: "What to expect when you join forces with us?",
            description: "We have carefully designed our business model and operating procedures by taking into account the various bottlenecks clients face when they work with offshore consultants. Our management team has dedicated considerable time to apply the best practices and insights they had garnered from their past experience into our handbook and the company ethos. We have made our onboarding process seamless by incorporating a white glove approach throughout. Communication, with proper speech and text comprehension, is a major worry for US companies when dealing with foreign entities for instance. SparkNation Labs fully eliminates that pain point so that our clients can keep focusing on core business. We focus on progressive learning not only on the technical side but also on high quality communication and work culture, designed to emulate high US standards."
        },
        {
            id: '2',
            title: "How to keep track of project progress and manage deadlines?",
            description: "To efficiently track project progress and meet deadlines, Sparknation Labs deploys a well-structured project management approach. We leverage agile methodologies, ensuring tasks are transparently outlined and frequently reviewed. Our teams have access to powerful project management tools and communication platforms to facilitate real-time collaboration. We also maintain a rigorous schedule, breaking projects into manageable sprints and ensuring that milestones are consistently met. We are able to create custom dashboards for you, highlighting key metrics and up to date progress statistics. Your project's success is our commitment, and we employ the best strategies to achieve it."
        },
        {
            id: '3',
            title: "How can our AI solutions help your business?",
            description: "Leveraging our AI capabilities at Sparknation Labs can boost your business in several ways. By fusing Generative AI’s power with human insights from our talented team of changemakers, we can help you increase operational efficiency, enhance personalization features, spur content with prompt engineering, craft predictive analytics and last but not least lead to further cost savings. AI helps you to stay ahead of the curve with invaluable insights, advanced security and on-demand support. We equip our teams with essential AI skills with a human eye that can help you strategize and validate ambitious ideas. Learn more on how you can extract more value by booking your free consulting session."
        },
        {
            id: '4',
            title: "What level of involvement will I have in the website development process?",
            description: "We understand that your website is a reflection of your brand, and we value your input throughout the development process. We believe and practice agile development processes, so the more you get involved and give feedback the better it will be for us to deliver the exact product you want. We strive to maintain a collaborative approach, ensuring your needs and vision are incorporated at every stage. We will schedule regular meetings to discuss progress, gather feedback, and answer any questions you may have. You can be as involved as you desire, from providing high-level direction to reviewing detailed design mockups."
        },
        {
            id: '5',
            title: "How do you ensure the security and maintenance of my website after launch?",
            description: "At Sparknation Labs, website security is paramount. We employ industry-leading security practices to protect your website from potential threats. This includes implementing secure coding practices, regular vulnerability scans, and staying updated on the latest security patches. We also offer ongoing website maintenance packages to ensure your website functions optimally and remains secure over time. These packages can include content updates, performance optimization, and security monitoring."
        },
        {
            id: '6',
            title: "What would it cost to get my website done?",
            description: "The cost of creating a website varies from client to client. The cost will be based on what your needs are. But at Sparknation labs we can guarantee you the best possible service for the cheapest price compared to any US tech provider. We are confident in our ability to provide the best service and slash your tech budget in half. We pride ourselves on being the most cost-efficient provider for your business. Our excellent team of experts will help you achieve the highest quality products for your company. To get an exact quote from us register a free consultation session here!"
        }
    ]

    // useEffect(() => {

    //     async function getFAQs() {
    //         const { data } = await axios.get(FAQ_API);
    //         setFAQs(data);
    //         setActiveFAQId(data[0]?._id);
    //     }
    //     getFAQs()

    // }, [])


    return (
        <section className='faq_sec py_10'>
            <div className="container">
                <div className="sc_title_wrapper center">
                    <h1 className="title_1 center">FAQs</h1>
                    {/* <p className='desc _568'>“Lorem ipsum dolor sit amet consectetur. Tortor tempus cursus leo dictumst elementum.</p> */}
                </div>
                <div className="accordion">
                    {
                        fAQs?.map(faq => (
                            <div className={`accordion_item ${(faq.id === activeFAQId) && activeFAQ && 'open'}`} key={faq.id}>
                                <div className="accordion_header">
                                    <h4
                                        onClick={() => {
                                            setActiveFAQId(faq.id)
                                            setActiveFAQ(prev => !prev)
                                        }}
                                    >
                                        {faq?.title}
                                    </h4>
                                </div>
                                <div className="accordion_body">
                                    <div className="accordion_body_inner">
                                        <p>{faq?.description}</p>
                                        {/* <div
                                            dangerouslySetInnerHTML={{ __html: faq && faq?.description }}
                                        /> */}
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                </div>
                {/* <div className="accordion">
                    <div className="accordion_item open">
                        <div className="accordion_header">
                            <h4>What to expect when you join forces with us?</h4>
                        </div>
                        <div className="accordion_body">
                            <div className="accordion_body_inner">
                                <p>We have carefully designed our business model and operating procedures by taking into account the various bottlenecks clients face when they work with offshore consultants. Our management team has dedicated considerable time to apply the best practices and insights they had garnered from their past experience into our handbook and the company ethos. We have made our onboarding process seamless by incorporating a white glove approach throughout. Communication, with proper speech and text comprehension, is a major worry for US companies when dealing with foreign entities for instance. Optivity fully eliminates that pain point so that our clients can keep focusing on core business. We focus on progressive learning not only on the technical side but also on high quality communication and work culture, designed to emulate high US standards.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion_item">
                        <div className="accordion_header">
                            <h4>What kind of deliverables are to be expected?</h4>
                        </div>
                        <div className="accordion_body">
                            <div className="accordion_body_inner">
                                <p>Having an FAQ section is a great way to present information about your product or service. Using the question-and-answer format makes it more relatable to your users.</p>
                            </div>
                        </div>
                    </div>
                    <div className="accordion_item">
                        <div className="accordion_header">
                            <h4>How long will the project take and how long until results can be measured?</h4>
                        </div>
                        <div className="accordion_body">
                            <div className="accordion_body_inner">
                                <p>Having an FAQ section is a great way to present information about your product or service. Using the question-and-answer format makes it more relatable to your users.</p>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </section>
    )
}

export default FAQs