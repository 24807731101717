// import { Link } from 'react-router-dom'
// import brandImg from '../../../../../Assets/Images/toyota.svg'

import "./ClientCard.css";

function ClientCard({ client, setShowModalContent, setPortfolioInfo }) {
  return (
    <div className="client_card">
      <img
        src={client.image}
        alt="brand"
        onClick={() => {
          setShowModalContent(true);
          setPortfolioInfo(client);
        }}
      />
    </div>
  );
}

export default ClientCard;
