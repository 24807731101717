import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import AppContext from "../../../../AppContext/AppContext";

import { RiCloseLine } from "react-icons/ri";
import logo from "../../../../Assets/Logos/nav-logo-lg.svg";

import "./NavSmall.css";

function NavSmall({ showSmallNav, smallNavToggle }) {
  const { setShowConsultationForm } = useContext(AppContext);
  const [activeNav, setActiveNav] = useState("home");

  return (
    <div className={`nav_small ${showSmallNav && "show"}`}>
      <div className="nav_smal_overlay" onClick={smallNavToggle}></div>
      <div className="nav_small_content">
        <button type="button" className="btn_close" onClick={smallNavToggle}>
          <RiCloseLine />
        </button>
        <div className="logo_wrapper">
          <Link to="/" onClick={smallNavToggle}>
            <img src={logo} alt="brand" className="logo" />
          </Link>
        </div>

        <nav>
          <a
            href="#home"
            className={`${activeNav === "home" ? "active" : ""}`}
            onClick={() => {
              smallNavToggle();
              setActiveNav("home");
            }}
          >
            home
          </a>
          <a
            href="#aboutUs"
            className={`${activeNav === "about" ? "active" : ""}`}
            onClick={() => {
              smallNavToggle();
              setActiveNav("about");
            }}
          >
            About Us
          </a>
          <a
            href="#services"
            className={`${activeNav === "service" ? "active" : ""}`}
            onClick={() => {
              smallNavToggle();
              setActiveNav("service");
            }}
          >
            services
          </a>
          <a
            href="#portfolio"
            className={`${activeNav === "portfolio" ? "active" : ""}`}
            onClick={() => setActiveNav("portfolio")}
          >
            Portfolio
          </a>
          <a
            href="#mtTeam"
            className={`${activeNav === "mtTeam" ? "active" : ""}`}
            onClick={() => {
              smallNavToggle();
              setActiveNav("mtTeam");
            }}
          >
            Meet The Team
          </a>
          {/* <a
            href="#testimonials"
            className={`${activeNav === "testimonial" ? 'active' : ''}`}
            onClick={() => {
              smallNavToggle()
              setActiveNav('testimonial')
            }}
          >
            Testimonials
          </a> */}
          <a
            href="#contactUs"
            className={`${activeNav === "contact" ? "active" : ""}`}
            onClick={() => {
              smallNavToggle();
              setActiveNav("contact");
            }}
          >
            Contact Us
          </a>
        </nav>
        <div className="text_center">
          <button
            type="button"
            className="btn fill"
            onClick={() => {
              setShowConsultationForm(true);
              smallNavToggle();
            }}
          >
            <span>Get Free Consultation</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default NavSmall;
