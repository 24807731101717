import { Link } from "react-router-dom";
import "./ConsultationForm.css";

import logoSm from "../../../Assets/Logos/nav-logo-sm.svg";
import axios from "axios";
import { useContext, useState } from "react";
import { toast } from "react-toastify";
import AppContext from "../../../AppContext/AppContext";

function ConsultationForm() {
  const [service, setService] = useState("");
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const { setShowConsultationForm } = useContext(AppContext);

  async function handleSubmit(e) {
    e.preventDefault();

    const itemData = { service, name, phone, email };

    const formData = await axios.post(
      process.env.REACT_APP_BACKEND_URL +
        "api/public/affiliateConsultancy/createConsultancyRequestForSNlabs",
      itemData
    );

    if (formData.status === 200) {
      toast.success("Data successfully submitted", {
        theme: "dark",
      });
      setService("");
      setName("");
      setPhone("");
      setEmail("");
      setShowConsultationForm(false);
    }
  }

  return (
    <div className="consultancy_form">
      <div className="modal_icon">
        <Link to="/" className="logo_wrapper">
          <img src={logoSm} alt="brand" className="logo_sm" />
        </Link>
        <h1 className="cf_title">REQUEST CONSULTANCY SESSION</h1>
      </div>
      <div className="form_wrapper">
        <form onSubmit={handleSubmit}>
          <div className="form_input">
            <label htmlFor="help">LET US HELP YOU</label>
            <select
              required
              value={service}
              onChange={(e) => setService(e.target.value)}
            >
              <option value="">Select Service </option>
              <option value="Web development">Web development</option>
              <option value="Mobile App Development">
                Mobile App Development
              </option>
              <option value="Web Applications and SaaS Solutions">
                Web Applications and SaaS Solutions
              </option>
              <option value="Data Labeling">Data Labeling</option>
              <option value="Digital Marketing">Digital Marketing</option>
              <option value="Startup Launchpad">Startup Launchpad</option>
            </select>
          </div>
          <div className="form_input">
            <label htmlFor="fullName">Full name</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="phone_email">
            <div className="form_input">
              <label htmlFor="phone">Phone</label>
              <input
                type="number"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
                required
              />
            </div>
            <div className="form_input">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
          </div>
          <div className="form_btn_wrapper center">
            <button type="submit" className="btn fill">
              <span>Get free consultation</span>
            </button>
          </div>
        </form>
        {/* <form onSubmit={handleSubmit}>
						<div className="form_input">
							<label htmlFor="help">LET US HELP YOU</label>
							<select
								value={selectedService}
								onChange={(e) =>
									setSelectedService(e.target.value)
								}
							>
								<option
									value="0"
									hidden
								>
									Select a Service
								</option>
								{services?.map((service) => (
									<option
										key={service?._id}
										value={service?._id}
									>
										{service?.name}
									</option>
								))}
							</select>
						</div>
						<div className="form_input">
							<label htmlFor="fullName">Full name</label>
							<input
								type="text"
								value={name}
								onChange={(e) => setName(e.target.value)}
							/>
						</div>
						<div className="phone_email">
							<div className="form_input">
								<label htmlFor="phone">Phone</label>
								<input
									type="number"
									value={phone}
									onChange={(e) => setPhone(e.target.value)}
								/>
							</div>
							<div className="form_input">
								<label htmlFor="email">Email</label>
								<input
									type="email"
									value={email}
									onChange={(e) => setEmail(e.target.value)}
								/>
							</div>
						</div>
						<div className="form_btn_wrapper center">
							<HexagonButton
								btnType="submit"
								text="Submit Request"
							/>
						</div>
					</form> */}
      </div>
    </div>
  );
}

export default ConsultationForm;
