import React from "react";

function Terms() {
  return (
    <section className="scu_sec py_10 privacy_policy">
      <div className="container">
        <div className="content_inner">
          <h2>Sparknation Labs LLC Terms of Use </h2>

          <div className="desc">
            <h3>Terms of Use</h3>
            <p>Effective Date: December 3rd, 2023</p>
          </div>
          <div className="desc">
            <h3>Agreement to Terms</h3>
            <p>
              By accessing or using the Sparknation Labs LLC ("SNLabs," "we," or
              "us") website (the "Site"), you agree to comply with and be bound
              by these Terms of Use. If you do not agree to these terms, please
              do not access or use the Site.
            </p>
          </div>
          <div className="desc">
            <h3>Intellectual Property</h3>
            <p>
              All content, design, graphics, text, logos, and other materials on
              the Site are the intellectual property of SNLabs and are protected
              by applicable copyright, trademark, and other intellectual
              property laws.
              <br />
              You may access and use the content for personal and non-commercial
              purposes. Any other use without our express written consent is
              strictly prohibited
            </p>
          </div>
          <div className="desc">
            <h3>Use of the Site</h3>
            <p>
              You agree to use the Site only for lawful purposes and in a manner
              consistent with all applicable local, state, national, and
              international laws and regulations. You further agree not to:
              <span>
                ttempt to gain unauthorized access to any portion of the Site or
                any other systems or networks connected to the Site.
                <br />
                <br />
                Interfere with or disrupt the operation of the Site or any
                servers or networks connected to the Site.
                <br />
                <br />
                Transmit or otherwise make available any content that is
                unlawful, harmful, threatening, abusive, harassing, defamatory,
                vulgar, obscene, or otherwise objectionable.
              </span>
            </p>
          </div>
          <div className="desc">
            <h3>Links to Other Websites</h3>
            <p>
              The Site may contain links to third-party websites. These links
              are provided for your convenience only. SNLabs has no control over
              the content of linked websites and is not responsible for their
              content. The inclusion of any link does not imply endorsement by
              SNLabs
            </p>
          </div>
          <div className="desc">
            <h3>Disclaimer of Warrantie</h3>
            <p>
              The Site is provided "as is" without any warranties, express or
              implied. SNLabs makes no representations or warranties regarding
              the accuracy, completeness, or availability of the content on the
              Site.
            </p>
          </div>
          <div className="desc">
            <h3>Limitation of Liability</h3>
            <p>
              To the fullest extent permitted by applicable law, SNLabs shall
              not be liable for any direct, indirect, incidental, special,
              consequential, or exemplary damages, including but not limited to,
              damages for loss of profits, goodwill, use, data, or other
              intangible losses resulting from the use or inability to use the
              Site.
            </p>
          </div>
          <div className="desc">
            <h3>Changes to Terms of Use</h3>
            <p>
              SNLabs reserves the right to update or modify these Terms of Use
              at any time. Your continued use of the Site after any such changes
              constitutes your acceptance of the new Terms of Use.
            </p>
          </div>
          <div className="desc">
            <h3>Contact Information</h3>
            <p>
              If you have any questions, concerns, or requests regarding these
              Terms of Use, you may contact us at:<br/>
               Sparknation Labs LLC <br/>
               Attn: Khandakar Waliduzzaman<br/>
                6 Olivers Park Drive, Annville, PA 17003<br/>
              Email: info@sparknationlabs.com
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Terms;
