
// import { ReactComponent as Facebook } from '../../../../../Assets/Icons/facebook-fill.svg'
// import { ReactComponent as Twitter } from '../../../../../Assets/Icons/twitter-fill.svg'
// import { ReactComponent as Instagram } from '../../../../../Assets/Icons/instagram-solid.svg'
import { ReactComponent as LinkedIn } from '../../../../../Assets/Icons/linkedin-fill.svg'

import './TMSocials.css'

function TMSocials({link}) {
    return (
        <div className='tms_links'>
            {/* <a href='facebook.com'> <Facebook /></a>
            <a href='facebook.com'> <Twitter /></a>
            <a href='facebook.com'> <Instagram /></a> */}
            <a href={link} target='_blank' rel="noreferrer"> <LinkedIn /></a>
        </div>
    )
}

export default TMSocials