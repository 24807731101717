import { useContext, useState } from 'react'
import AppContext from '../../../../AppContext/AppContext'
import scBottomImg from '../../../../Assets/Images/service-bottom-img.png'
import ServiceCard from './ServiceCard/ServiceCard'
import Modal from '../../../Layouts/Modal/Modal'
import SingleService from './SingleService/SingleService'

import './OurServices.css'

function OurServices() {

    const { showSingleService, setshowSingleService } = useContext(AppContext)
    const [serviceInfo, setServiceInfo] = useState(null)

    const ourServices = [
        {
            // title: "Website Development",
            title: "Web Design and Development",
            shortDescription: "Reimagining online identity",
            description: "Whether you're starting from scratch or need to revamp your UX/UI, our developers create web experiences that are visually stunning and technically sound. Our stack includes React and NextJS for front-end, and Node and ExpressJS for back-end with MongoDB and SQL expertise.",
            image: "https://static-projects-test.sgp1.cdn.digitaloceanspaces.com/optivity/services/web-design 1.svg"
        },
        {
            // title: "Mobile App Development",
            title: "Mobile App Design and Development",
            shortDescription: "Engineering the next-gen apps",
            description: "Allow your audience to seamlessly interact anywhere with your business using our iOS and Android app solutions. We transmute your vision and concept into intuitive, visually pleasing apps. Our experts create native and cross platform apps , harnessing frameworks like Flutter, Kotlin, and Swift",
            image: "https://static-projects-test.sgp1.cdn.digitaloceanspaces.com/optivity/services/mobile-development.svg"
        },
        {
            // title: "Web Applications and SaaS Solutions",
            title: "PWAs, SaaS & Enterprise Products",
            shortDescription: "Discovering new dimensions",
            description: "We supercharge clients with intuitive and scalable applications using the right agile powered framework. We engineer Progressive Web Apps, Single Page Apps, SaaS platforms in addition to enterprise tools such as fintech, solutions, learning management systems (LMS), or CRMs.",
            image: "https://static-projects-test.sgp1.cdn.digitaloceanspaces.com/optivity/services/responsive 1.svg"
        },
        {
            title: "Data Labelling",
            shortDescription: "Precision for Machine Intelligence",
            description: "For training and executing the best machine learning models, accurate and reliable data annotation are essential. We prepare, label, quality test and deliver the labeled data with accuracy and speed. Trust SparkNation Labs to provide high-quality data labeling services utilizing tools such as Google AI and Labelbox.",
            image: "https://static-projects-test.sgp1.cdn.digitaloceanspaces.com/optivity/services/price-tag.svg"
        },
        {
            title: "Digital Marketing",
            shortDescription: "Amplifying brand power",
            description: "Leverage the quality and craftsmanship of our strategic campaigns. We'll elevate your online presence, enhance engagement, and maximize conversions, ensuring your business excels. Our content is powered by Adobe, Microsoft and Facebook Business Suites among others, along with 2D animation features where needed.",
            image: "https://static-projects-test.sgp1.cdn.digitaloceanspaces.com/optivity/services/social-media-marketing.svg"
        },
        {
            // title: "Startup Launchpad",
            title: "Customizable Startup Launchpad",
            shortDescription: "Comprehensive tech stack for startups",
            description: "A custom designed package to catapult your startup. Whether you’re working on your MVP or pursuing rapid expansion, we provide expert guidance, software development, ERP solutions and digital marketing, augmenting your team for expansion by accessing our whole suite of products.",
            image: "https://static-projects-test.sgp1.cdn.digitaloceanspaces.com/optivity/services/rocket.svg"
        },
        {
            title: "UI/UX Optimization",
            shortDescription: "Improve user satisfaction",
            description: "Enhance or fully revamp exisitng platform interface and usability.",
            image: "/services/uiux.png"
        },
        {
            title: "Blockchain & WEB3",
            shortDescription: "Leverage future technologies",
            description: "Supercharge your digital assets platform and Web3 ambitions.",
            image: "/services/blockchain.png"
        },

    ]

    return (
        <>
            <section className="services_sec pt_10" id='services'>
                <div className="container pb_10">
                    <div className="sc_title_wrapper center">
                        <h1 className="title_1 center">Our Services</h1>
                        <p className='desc'>At Sparknation Labs, we empower your business to thrive in the digital age with a comprehensive suite of high-demand tech services.. Our team of experts are committed to go the extra mile delivering meticulous solutions that drive success. Every process goes through critical checks with QA (Quality Assurance) testing at the heart of every product. Explore our services below to see how we can help your business achieve its digital potential.</p>
                    </div>
                    <div className="grid_container">
                        {ourServices.map(item => (
                            <ServiceCard
                                setServiceInfo={setServiceInfo}
                                item={item}
                                key={item?.title}
                                setshowSingleService={setshowSingleService}
                            />
                        ))}
                    </div>
                    <div className="desc_content">
                        <p className="desc portfolio-desc">Our services incorporate smart cyber security, reliable cloud practices and rigorous QA testing to make sure you are always protected and one step ahead of the pack. We prioritize aesthetics with substance and utility through our best-in-class UX and UI capabilities. Customizability is essential for businesses to excel,and our dynamic team of experts stand ready to help you achieve it. Learn more about our capabilities and services. Contact us today to start your journey with Sparknation Labs.

                            Learn more about our capabilities and services.
                            &nbsp;
                            <a href="#contactUs">Contact Us</a>
                            &nbsp;
                            today to start your journey with Sparknation Labs.
                        </p>
                    </div>
                </div>
                <div className="service_sc_bottom_img">
                    <img src={scBottomImg} alt="rightThumb" />
                </div>
            </section>

            {showSingleService &&
                <Modal>
                    <SingleService serviceInfo={serviceInfo} />
                </Modal>
            }
        </>
    )
}

export default OurServices