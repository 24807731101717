import { useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AppContext from "./AppContext/AppContext";

import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import Navbar from "./Components/Partials/Navbar/Navbar";
import Home from "./Components/Screens/Home/Home";
import Footer from "./Components/Partials/Footer/Footer";
import ConsultationForm from "./Components/Layouts/ConsultationForm/ConsultationForm";
import Modal from "./Components/Layouts/Modal/Modal";
import { ToastContainer } from "react-toastify";
import PrivacyPolicy from "./Components/Screens/PrivacyPolicy/PrivacyPolicy";
import Terms from './Components/Screens/Terms/Terms';
import ScrollTop from "./ScrollTop";

function App() {
  const { showConsultationForm } = useContext(AppContext);

  return (
    <BrowserRouter>
    <ScrollTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
      </Routes>
      <Footer />

      {showConsultationForm && (
        <Modal>
          <ConsultationForm />
        </Modal>
      )}

      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
